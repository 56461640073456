import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './style.scss';

export const sliderCurve = Math.exp;
export const inverseCurve = Math.log;

const makeMarks = marks =>
  Object.keys(marks).reduce((acc, cur) => {
    acc[inverseCurve(cur)] = marks[cur];
    return acc;
  }, {});

const RangeSlider = ({
  min,
  max,
  marks,
  onChange,
  stepsInRange,
  onAfterChange,
  onBeforeChange,
  onBlur,
  onFocus,
  ...props
}) => (
  <Slider
    min={inverseCurve(min)}
    max={inverseCurve(max)}
    marks={makeMarks(marks)}
    step={(inverseCurve(max) - inverseCurve(min)) / stepsInRange}
    onChange={onChange}
    onAfterChange={onAfterChange}
    onBeforeChange={onBeforeChange}
    onBlur={onBlur}
    onFocus={onFocus}
    {...props}
  />
);

RangeSlider.propTypes = {
  marks: PropTypes.objectOf(PropTypes.string),
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  onAfterChange: PropTypes.func,
  onBeforeChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  stepsInRange: PropTypes.number,
};

RangeSlider.defaultProps = {
  marks: undefined,
  max: 100,
  min: 0,
  onChange: undefined,
  onAfterChange: undefined,
  onBeforeChange: undefined,
  onBlur: undefined,
  onFocus: undefined,
  stepsInRange: 100,
};

export default RangeSlider;
