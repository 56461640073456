import PropTypes from 'prop-types';
import React from 'react';
import Typography from '../../../../components/Typography';
import RangeSlider, { sliderCurve } from '../../../../components/RangeSlider';

const PricingSlider = ({ label, value, helperText, onChange, onAfterChange, min, max, marks, stepsInRange }) => {
  const formattedMarks = marks.reduce(
    (acc, mark) => ({
      ...acc,
      [mark.value]: mark.label,
    }),
    {},
  );

  return (
    <>
      <Typography align="center" variant="h6" component="span" className="d-block mb-16">
        {label}
      </Typography>
      <div className="d-flex align-items-center justify-content-between">
        <Typography variant="body-medium" weight="semi-bold">
          {new Intl.NumberFormat('nl-NL', { maximumFractionDigits: 0 }).format(sliderCurve(value))}
        </Typography>
        <Typography>{helperText}</Typography>
      </div>
      <RangeSlider
        min={min}
        max={max}
        marks={formattedMarks}
        onChange={onChange}
        onAfterChange={onAfterChange}
        stepsInRange={stepsInRange}
        value={value}
      />
    </>
  );
};

PricingSlider.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  marks: PropTypes.object,
  max: PropTypes.number,
  min: PropTypes.number,
  onAfterChange: PropTypes.func,
  onChange: PropTypes.func,
  stepsInRange: PropTypes.number,
  value: PropTypes.number,
};

PricingSlider.defaultProps = {
  helperText: undefined,
  label: undefined,
  marks: undefined,
  max: undefined,
  min: undefined,
  onAfterChange: undefined,
  onChange: undefined,
  stepsInRange: undefined,
  value: undefined,
};

export default PricingSlider;
