import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../../components/Layout';
import getSchemaBreadcrumbs from '../../utils/getSchemaBreadcrumbs';
import HeadingBlock from '../../components/blocks/HeadingBlock';
import Typography from '../../components/Typography';
import TotalsDisplay from './components/TotalsDisplay/TotalsDisplay';
import OptionSwitch from '../../components/OptionSwitch';
import { sliderCurve } from '../../components/RangeSlider';
import TiersSwiper from './components/TiersSwiper';
import { inverseCurve } from '../../components/RangeSlider/RangeSlider';
import formatLocale from '../../utils/formatLocale';
import PricingSlider from './components/PricingSlider/PricingSlider';

const PricingPage = ({ data, location }) => {
  const { site, markdownRemark } = data;
  const { frontmatter, fields } = markdownRemark;
  const { slug } = fields;
  const { locale, localizations, seo, pricingPage } = frontmatter;
  const { banner, calculator } = pricingPage;
  const searchParams = new URLSearchParams(location.search);

  const [selectedValues, setSelectedValues] = useState(
    calculator.sliders.reduce(
      (acc, slider) => ({ ...acc, [slider?.key]: inverseCurve(searchParams.get(slider?.key) ?? slider?.default) }),
      {},
    ),
  );
  const [activePricingPeriod, setActivePricingPeriod] = useState(searchParams.get('pricingPeriod') || 'yearly');
  const [selectedTotals, setSelectedTotals] = useState(
    calculator.sliders.reduce((acc, slider) => ({ ...acc, [slider?.key]: 0 }), {}),
  );

  const handleChangePricingPeriod = newValue => {
    if (activePricingPeriod !== newValue) {
      setActivePricingPeriod(newValue);
    }
  };

  const handleChangeSelectedValues = key => newValue => {
    setSelectedValues({ ...selectedValues, [key]: newValue });
  };

  const handleUpdateSearchParams = () => {
    const params = new URLSearchParams(
      Object.keys(selectedValues).reduce((acc, key) => ({ ...acc, [key]: sliderCurve(selectedValues[key]) }), {}),
    ).toString();
    const newUrl = `${location.pathname}?${params}&pricingPeriod=${activePricingPeriod}`;

    window?.history.replaceState(null, '', newUrl);
  };

  return (
    <Layout
      location={location}
      slug={slug}
      locale={locale}
      localizations={localizations}
      headerOptions={{ bgColor: 'afosto-dark', color: 'dark' }}
    >
      <Helmet
        title={seo.title}
        meta={[
          {
            name: 'description',
            content: seo.description,
          },
        ]}
      >
        <html lang={formatLocale(locale)} />
        <script type="application/ld+json">
          {`
            [
              ${getSchemaBreadcrumbs(location)}
              {
                "@context": "http://schema.org",
                "@type": "Organization",
                "name": "${site?.siteMetadata?.siteName}",
                "logo": "${site?.siteMetadata?.logo}",
                "@id": "${site?.siteMetadata?.company?.kvk}",
                "url": "${site?.siteMetadata?.siteUrl}",
                "foundingDate": "${site?.siteMetadata?.company?.foundingDate}",
                "founders": ["${site?.siteMetadata?.company?.founders.join('", "')}"],
                "contactPoint": {
                  "@context": "http://schema.org",
                  "@type": "contactPoint",
                  "contactType": "customer service",
                  "telephone": "${site?.siteMetadata?.company?.phoneNumber}",
                  "email": "${site?.siteMetadata?.company?.email}"
                },
                "sameAs": ["${site?.siteMetadata?.twitter}", "${site?.siteMetadata?.facebook}"],
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "${site?.siteMetadata?.address?.streetAddress}",
                  "addressLocality": "${site?.siteMetadata?.address?.locality}",
                  "addressRegion": "${site?.siteMetadata?.address?.region}",
                  "postalCode": "${site?.siteMetadata?.address?.postalCode}",
                  "addressCountry": "${site?.siteMetadata?.address?.country}"
                }
              }
            ]
          `}
        </script>
      </Helmet>
      <HeadingBlock
        title={banner.title}
        contents={[{ cta_label_1: banner.buttonLabel, cta_url_1: banner.buttonLink, body: banner.subTitle }]}
        color="dark"
      />
      <div className="container pt-96">
        <section className="row align-items-lg-center justify-content-lg-between">
          <div className="col-12 col-lg-5">
            <Typography variant="h3" component="h2" className="text-center text-lg-left mb-48 mb-sm-64 mb-lg-0">
              {calculator.title}
            </Typography>
          </div>
          <div className="col-12 col-lg-auto">
            <OptionSwitch
              options={calculator.pricingPeriods}
              onClick={handleChangePricingPeriod}
              value={activePricingPeriod}
            />
          </div>
        </section>

        <section className="row flex-lg-row-reverse justify-content-center justify-content-lg-between pt-64">
          <div className="col-12 col-sm-10 col-lg-7">
            {calculator?.sliders?.map(({ key, label, helper, min, max, marks, stepsInRange }) => (
              <PricingSlider
                stepsInRange={stepsInRange}
                label={label}
                onChange={handleChangeSelectedValues(key)}
                value={selectedValues[key]}
                max={max}
                marks={marks}
                min={min}
                onAfterChange={handleUpdateSearchParams}
                helperText={helper}
                key={key}
              />
            ))}

            {/* <Typography align="center" variant="h6" component="span" className="d-block mb-16"> */}
            {/*  {calculator.sliders.optimisations.label} */}
            {/* </Typography> */}
            {/* <div className="d-flex align-items-center justify-content-between"> */}
            {/*  <Typography variant="body-medium" weight="semi-bold"> */}
            {/*    {new Intl.NumberFormat('nl-NL', { maximumFractionDigits: 0 }).format(sliderCurve(selectedOptimisation))} */}
            {/*  </Typography> */}
            {/*  <Typography>{calculator.sliders.optimisations.helper}</Typography> */}
            {/* </div> */}
            {/* <RangeSlider */}
            {/*  min={50000} */}
            {/*  max={2500000} */}
            {/*  marks={{ 100000: '100K', 500000: '500K', 1000000: '1M', 2500000: '2.5M' }} */}
            {/*  onChange={handleChangeOptimisations} */}
            {/*  onAfterChange={handleUpdateSearchParams} */}
            {/*  stepsInRange={1000} */}
            {/*  value={selectedOptimisation} */}
            {/* /> */}

            {/* <Typography align="center" variant="h6" component="span" className="d-block mb-16 mt-40"> */}
            {/*  {calculator.sliders.bytes.label} */}
            {/* </Typography> */}
            {/* <div className="d-flex align-items-center justify-content-between"> */}
            {/*  <Typography variant="body-medium" weight="semi-bold"> */}
            {/*    {new Intl.NumberFormat('nl-NL', { maximumFractionDigits: 0 }).format(sliderCurve(selectedTransfers))} */}
            {/*  </Typography> */}
            {/*  <Typography>{calculator.sliders.bytes.helper}</Typography> */}
            {/* </div> */}
            {/* <RangeSlider */}
            {/*  min={10} */}
            {/*  max={750} */}
            {/*  marks={{ 25: '25', 100: '100', 250: '250', 750: '750' }} */}
            {/*  onChange={handleChangeTransfers} */}
            {/*  onAfterChange={handleUpdateSearchParams} */}
            {/*  stepsInRange={750} */}
            {/*  value={selectedTransfers} */}
            {/* /> */}
          </div>
          <div className="col-12 col-lg-4">
            <TotalsDisplay
              discount={calculator.yearlyDiscount}
              monthlyCost={Object.values(selectedTotals).reduce((acc, value) => acc + value)}
              monthlyUnit={calculator.total.monthlyUnit}
              currency={calculator.currency}
              isYearly={activePricingPeriod === 'yearly'}
            />
          </div>
        </section>
      </div>
      <section className="overflow-hidden">
        <div className="container pb-96 pb-sm-144">
          {calculator.tables.map(table => (
            <div className="pt-64" key={table?.key}>
              <Typography variant="h6" component="h2" className="mb-24">
                {table.title}
              </Typography>
              <TiersSwiper
                tiers={table.tiers}
                yearlyLabel={calculator.total.payYearly}
                yearlyDiscount={calculator.yearlyDiscount}
                amount={Math.round(sliderCurve(selectedValues[table?.key]))}
                showDiscount={activePricingPeriod === 'yearly'}
                key={table?.key}
                id={table?.key}
                totals={selectedTotals}
                setTotalPrice={setSelectedTotals}
              />
            </div>
          ))}

          {/* <div className="pt-64"> */}
          {/*  <Typography variant="h6" component="h2" className="mb-24"> */}
          {/*    {calculator.tables.optimisations.title} */}
          {/*  </Typography> */}
          {/*  <TiersSwiper */}
          {/*    tiers={calculator.tables.optimisations.tiers} */}
          {/*    yearlyLabel={calculator.total.payYearly} */}
          {/*    yearlyDiscount={calculator.yearlyDiscount} */}
          {/*    amount={Math.round(sliderCurve(selectedOptimisation))} */}
          {/*    showDiscount={activePricingPeriod === 'yearly'} */}
          {/*    setTotalPrice={setTotalOptimisationsPrice} */}
          {/*  /> */}
          {/* </div> */}
          {/* <div className="pt-64"> */}
          {/*  <Typography variant="h6" component="h2" className="mb-24"> */}
          {/*    {calculator.tables.transfers.title} */}
          {/*  </Typography> */}
          {/*  <TiersSwiper */}
          {/*    tiers={calculator.tables.transfers.tiers} */}
          {/*    yearlyLabel={calculator.total.payYearly} */}
          {/*    yearlyDiscount={calculator.yearlyDiscount} */}
          {/*    amount={Math.round(sliderCurve(selectedTransfers))} */}
          {/*    showDiscount={activePricingPeriod === 'yearly'} */}
          {/*    setTotalPrice={setTotalTransfersPrice} */}
          {/*  /> */}
          {/* </div> */}
        </div>
      </section>
      <div className="overflow-hidden mx-lg-15">
        <section className="container-lg px-0 mb-128 mb-sm-144">
          <div className="bg-afosto-dark mx-lg-n64 pt-64 pt-sm-64 pb-56 pb-sm-64 pb-lg-56 text-center">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-sm-10 col-lg-6">
                  <Typography variant="overline" component="div" color="gray-600" className="mb-48 mb-sm-32">
                    {calculator.enterprise?.overline}
                  </Typography>
                  <Typography variant="h2" color="white" className="mb-24 mb-sm-16">
                    {calculator.enterprise?.title}
                  </Typography>
                  <Typography variant="body-large" color="gray-400" className="mb-80 mb-sm-48">
                    {calculator.enterprise?.lead}
                  </Typography>
                  <Link className="btn btn-primary" to={calculator.enterprise?.button?.to}>
                    {calculator.enterprise?.button?.label}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="container mb-144">
        <div className="row col-xs-12 col-md-6">
          <Typography color="gray-af-120" variant="h2" className="mb-24">
            {calculator.discover.title}
          </Typography>
          <Typography color="gray-af-110" variant="body-large">
            {calculator.discover.text}
          </Typography>
        </div>
      </section>
    </Layout>
  );
};

PricingPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

PricingPage.defaultProps = {
  data: {
    markdownRemark: {
      frontmatter: {},
      timeToRead: 0,
    },
  },
  location: undefined,
};

export default PricingPage;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        logo
        siteUrl
        siteName
        twitter
        facebook
        company {
          foundingDate
          founders
          phoneNumber
          email
        }
        address {
          streetAddress
          locality
          region
          postalCode
          country
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        pricingPage {
          banner {
            title
            subTitle
            buttonLabel
            buttonLink
          }
          calculator {
            title
            currency
            yearlyDiscount
            discover {
              title
              text
            }
            enterprise {
              overline
              title
              lead
              button {
                label
                to
              }
            }
            pricingPeriods {
              label
              value
            }
            sliders {
              label
              helper
              default
              key
              max
              min
              stepsInRange
              marks {
                value
                label
              }
            }
            tables {
              title
              tiers {
                title
                min
                max
                fromSubtitle
                unitPrice
                unitSubtitle
              }
              key
            }
            total {
              monthlyUnit
              payYearly
            }
          }
        }
        seo {
          title
          description
        }
        locale
        localizations {
          locale
          slug
        }
      }
    }
  }
`;
