import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './style.scss';

const Label = ({ size, color, content, className }) => (
  <span className={clsx(className, 'label', { [`label-${size}`]: size, [`label-${color}`]: color })}>{content}</span>
);

Label.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
  content: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

Label.defaultProps = {
  className: undefined,
  color: 'success',
  content: null,
  size: 'medium',
};

export default Label;
