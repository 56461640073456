import React, { useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
import './style.scss';
import Card from '../../../../components/Card';
import CardBody from '../../../../components/CardBody';
import Typography from '../../../../components/Typography';
import Label from '../../../../components/Label';
import Divider from '../../../../components/Divider';

const TiersSwiper = ({ tiers, yearlyDiscount, showDiscount, amount, setTotalPrice, id, totals }) => {
  const [instance, setInstance] = useState(null);
  const [selected, setSelected] = useState(0);

  return (
    <Swiper
      className="swiper-overflow"
      slidesPerView={1}
      centeredSlides
      spaceBetween={12}
      breakpoints={{
        768: {
          slidesPerView: 2,
          centeredSlides: false,
        },
        992: {
          slidesPerView: 3,
          centeredSlides: false,
          spaceBetween: 30,
        },
        1200: {
          slidesPerView: 4,
          centeredSlides: false,
        },
      }}
      onSwiper={setInstance}
    >
      {tiers.map((tier, idx) => {
        const showPrice = amount >= tier.min;
        const isSelected =
          (amount >= tier.min && amount < tier.max) || (amount >= tier.min && idx + 1 === tiers.length);

        if (isSelected && selected !== idx) {
          instance?.slideTo(idx);
          setSelected(idx);
        }

        let totalCost = tiers.slice(0, idx).reduce((acc, { min, max, unitPrice }) => {
          let valueToAdd = 0;
          if (amount > max - 1) {
            valueToAdd += (max - 1 - min) * unitPrice;
          } else {
            valueToAdd += (amount - min) * unitPrice;
          }
          return acc + valueToAdd;
        }, 0);

        totalCost += (amount - tier.min) * tier.unitPrice;
        totalCost = showDiscount ? totalCost - (totalCost / 100) * yearlyDiscount : totalCost;

        if (isSelected && totals[id] !== Math.ceil(totalCost)) {
          setTotalPrice({ ...totals, [id]: Math.ceil(totalCost) });
        }

        return (
          <SwiperSlide>
            <Card border elevation={0} selected={isSelected} className="h-100 position-relative">
              <CardBody className="d-flex flex-column p-32">
                {showDiscount && <Label className="discount-label" color="success" content={`-${yearlyDiscount}%`} />}
                <Typography variant="h6" component="div" align="center" className="mb-20">
                  {tier.title}
                </Typography>
                {showPrice && (
                  <>
                    <div className="price-display small justify-content-center mb-20">
                      <span className="currency">&euro;</span>
                      <span className="price">{new Intl.NumberFormat('nl-NL').format(Math.ceil(totalCost))}</span>
                      <span className="unit">/ pm</span>
                    </div>
                  </>
                )}
                {!showPrice && (
                  <>
                    <Typography variant="h4" weight="medium" component="div" align="center">
                      {new Intl.NumberFormat('nl-NL').format(tier.min)}
                    </Typography>
                    <Typography variant="body-medium" component="div" align="center" className="mt-16">
                      {tier.fromSubtitle}
                    </Typography>
                  </>
                )}

                <div className="mt-auto pt-32 mb-32">
                  <Divider />
                </div>
                <Typography variant="body-medium" component="div" align="center" weight="medium">
                  {new Intl.NumberFormat('nl-NL').format(tier.min)} - {new Intl.NumberFormat('nl-NL').format(tier.max)}
                </Typography>
                <Typography variant="body-medium" component="div" align="center" className="mt-8">
                  {tier.rangeSubtitle}
                </Typography>
                <Typography
                  className="mt-24 mb-8"
                  variant="body-medium"
                  component="div"
                  align="center"
                  weight="medium"
                  color="primary-600"
                >
                  {new Intl.NumberFormat('nl-NL', {
                    style: 'currency',
                    currency: 'EUR',
                    maximumFractionDigits: 6,
                  }).format(tier.unitPrice)}
                </Typography>
                <Typography variant="body-medium" component="div" align="center">
                  {tier.unitSubtitle}
                </Typography>
              </CardBody>
            </Card>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

TiersSwiper.propTypes = {
  amount: PropTypes.number,
  setTotalPrice: PropTypes.func,
  showDiscount: PropTypes.bool,
  tiers: PropTypes.array,
  yearlyDiscount: PropTypes.number,
  id: PropTypes.string,
  totals: PropTypes.object,
};

TiersSwiper.defaultProps = {
  amount: undefined,
  setTotalPrice: undefined,
  showDiscount: false,
  tiers: [],
  yearlyDiscount: undefined,
  id: undefined,
  totals: {},
};

export default TiersSwiper;
