import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Label from '../../../../components/Label/Label';

const TotalsDisplay = ({ discount, monthlyCost, isYearly }) => (
  <div className="totals-display mt-64 mt-lg-0">
    {isYearly && <Label content={`-${discount}%`} className="discount-label" size="large" />}
    <div className="price-display mb-16">
      <span className="currency">&euro;</span>
      <span className="price">{new Intl.NumberFormat('nl-NL', { maximumFractionDigits: 0 }).format(monthlyCost)}</span>
      <span className="unit">/ pm</span>
    </div>
  </div>
);

TotalsDisplay.propTypes = {
  discount: PropTypes.number,
  monthlyCost: PropTypes.number,
  isYearly: PropTypes.bool,
};

TotalsDisplay.defaultProps = {
  discount: 0,
  monthlyCost: 0,
  isYearly: false,
};

export default TotalsDisplay;
