import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './style.scss';

const OptionSwitch = ({ options, onClick, value, className }) => {
  const handleClick = newValue => () => {
    if (onClick && typeof onClick === 'function') {
      onClick(newValue);
    }
  };

  return (
    <div className={clsx('option-switch', className)}>
      {options.map(option => (
        <button
          type="button"
          onClick={handleClick(option.value)}
          className={clsx('option-switch-option', { active: option.value === value })}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

OptionSwitch.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  value: PropTypes.string,
};

OptionSwitch.defaultProps = {
  className: undefined,
  onClick: undefined,
  options: [],
  value: undefined,
};

export default OptionSwitch;
